<script>
import PartnersElement from './PartnersElement.vue';

export default {
	components: {
		PartnersElement,
	},
	data: () => {
		return {
			partnerHeading: 'Avec le soutien de',
			partners: [
			{
					id: 1,
					title: 'Loterie Nationale',
					img: require('@/assets/images/partners/institutional/loterie-nationale.png'),
					website: 'https://www.loterie-nationale.be/bien-plus-que-jouer/bonnes-causes'
			},
			{
					id: 2,
					title: 'Province de Liège',
					img: require('@/assets/images/partners/institutional/province-liege.png'),
					website: 'https://provincedeliege.be/fr'
			},
			{
					id: 3,
					title: 'CCSTP',
					img: require('@/assets/images/partners/institutional/ccstp.png'),
					website: 'https://www.ccstp.be/'
			},
			{
					id: 4,
					title: 'Ville de Stavelot',
					img: require('@/assets/images/partners/institutional/ville-stavelot.png'),
					website: 'https://www.stavelot.be/'
			},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-medium text-2xl sm:text-5xl text-primary-dark dark:text-secondary-light"
		>
			{{ partnerHeading }}
		</p>
		<div class="grid grid-cols-1 sm:grid-cols-4 mt-10 sm:mt-14 gap-2">
			<PartnersElement
				v-for="partner in partners"
				:key="partner.id"
				:partner="partner"
			/>
		</div>
	</div>
</template>
