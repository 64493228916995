<script>
import Timeline from '@/components/shared/Timeline';
import Products from './Products';
import Partners from './Partners';
import Separator from '@/components/reusable/Separator.vue'
import Slider from './1874';

export default {
	name: 'Home',
	components: {
		Timeline,
		Products,
		Partners,
		Separator,
		Slider,
	},
	data() {
    return {
      program: [
        { time: 'Entrée gratuite', description: "Port du costume d'époque vivement souhaité" },
        { time: '12h30', description: "Ouverture des festivités dans la Cour de l'Abbaye" },
        { time: 'À partir de 12h30', description: 'Village des confréries' },
		{ time: 'À partir de 14h', description: "Animations d'époque : Fabrication de couteaux artisanaux, réparation d'instruments anciens, démonstration de vannerie, atelier de céramique, balade à dos d'âne, jeux en bois pour petits et grands, château gonflable, ..." },
        { time: '14h', description: 'Royale Orphée - Harmonie', logo: "orphee.jpeg" },
        { time: '15h', description: 'Réveil Ardennais - Danse traditionnelle' },
        { time: '16h', description: "Choeur Royal les Artisans Réunis - Choeur d'hommes", logo: "artisans.png" },
        { time: '17h', description: "Accor'Danse - Musique wallonne et traditionnelle", logo: "accordanse.png" },
        { time: '18h', description: "La Royale Malmédienne - Choeur d'hommes", logo: "malmedienne.webp" },
        { time: '19h', description: "Turlututrad - Musique festive" },
        { time: '20h30', description: "Proyecto Cubano - Musique cubaine" },
        { time: '22h30', description: "Bugul Noz - Musique punk-celtique", logo: "bugul-noz.png" },
        { time: '0h', description: "Les Marteaux - Streetband", logo: "marteaux.jpeg" },
        { time: 'Tout au long de la journée', description: "Bar et petite restauration" },
      ]
    };
  }

};
</script>

<template>
	<div class="mx-auto">
		<Timeline id="Timeline" class="container mx-auto" />
		<Separator />
		<Slider :events="program" :eventsPerSlide="1" :autoplayInterval="5000" />
		<Separator />

		<Products />
		<Separator />

		<Partners />

	</div>
</template>

<style scoped></style>
